import Form from '@/components/form/Form.vue';
import api from '@/api/projects'

export default {
	components: {Form},
	metaInfo: {
		title: 'Our Work – ImmerseFX'
	},
	created() {
		api.getAll(this)
	},
	computed: {
		dataList () {
			if (this.state.projects?.data) {
				return this.state.projects.data
			}
			return []
		}
	}
}
